import * as R from 'ramda';

import {
  Anchor,
  InPageNavigationServiceV1,
  InPageNavigationServiceV1Config,
} from './types';

const defaultConfigValues: Partial<InPageNavigationServiceV1Config> = {
  serviceEnabled: true,
};

class InPageNavigationServiceV1Imp implements InPageNavigationServiceV1 {
  private readonly config: InPageNavigationServiceV1Config;
  private orderedAnchorList: Anchor[];
  private currentActiveId?: string;

  public constructor(inputConfig: InPageNavigationServiceV1Config) {
    this.config = { ...defaultConfigValues, ...inputConfig };
    this.orderedAnchorList = [];
  }

  public getConfig(): InPageNavigationServiceV1Config {
    return this.config;
  }

  public getOrderedAnchorsList() {
    return this.orderedAnchorList;
  }

  public getActiveId() {
    return this.currentActiveId;
  }

  public deleteOrderedAnchorList() {
    this.orderedAnchorList = [];
    return this.orderedAnchorList;
  }

  public registerAnchor(anchor: Anchor): void {
    if (!R.includes(anchor.id, R.pluck('id', this.orderedAnchorList))) {
      this.orderedAnchorList = [...this.orderedAnchorList, anchor].sort(
        this.sortAnchors
      );
    } else {
      this.orderedAnchorList = this.orderedAnchorList
        .map((anch) => (anch.id === anchor.id ? anchor : anch))
        .sort(this.sortAnchors);
    }
  }

  public setActiveId(id: string): void {
    this.currentActiveId = id;
  }

  private sortAnchors(a: Anchor, b: Anchor): number {
    if (a.getPosition() < b.getPosition()) {
      return -1;
    }
    if (a.getPosition() > b.getPosition()) {
      return 1;
    }

    return 0;
  }
}

export {
  InPageNavigationServiceV1,
  InPageNavigationServiceV1Imp,
  InPageNavigationServiceV1Config,
};
