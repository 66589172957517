/* eslint-disable @typescript-eslint/no-floating-promises */
import type { Logger } from '@feature-hub/core';
import type { RenderMode } from '@oneaudi/render-mode-service';
import React, { useEffect, useState } from 'react';
import { useContent } from '@oneaudi/feature-app-utils';
import type { AsyncStateHolder } from './FeatureHubAppDefinition';
import { createInitialState } from './FeatureHubAppDefinition';
import Anchor from './components/Anchor';
import type { AnchorProps } from './components/AnchorTypes';
import { mapHeadlessContent } from './utils/mapHeadlessContent';
import { Content, HeadlessContent } from './FeatureAppTypes';

interface FeatureAppProps {
  readonly asyncStateHolder: AsyncStateHolder;
  readonly logger: Logger;
  readonly renderMode: RenderMode;
}

const FeatureApp: React.FC<FeatureAppProps> = ({
  asyncStateHolder,
  logger,
  renderMode,
}: FeatureAppProps) => {
  // when asyncStateHolder is an object it represents the serialized state coming from the server
  // ready to be used as the initial state
  const [state, setState] = useState<AnchorProps | undefined>(
    typeof asyncStateHolder === 'object' ? asyncStateHolder : undefined,
  );

  /* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-redundant-type-constituents, @typescript-eslint/no-unsafe-assignment,  @typescript-eslint/no-unsafe-argument */
  const initialContent = useContent<Content | HeadlessContent | any>();
  const content = mapHeadlessContent(initialContent);

  useEffect(() => {
    let mounted = true;
    if (state && content) {
      createInitialState(content, renderMode).then((newState) => {
        if (mounted) {
          setState(newState);
        }
      });
    }
    return () => {
      mounted = false;
    };
  }, [initialContent]);

  useEffect(() => {
    // when asyncStateHolder is a function it means the state could not be properly serialized by
    // the server and it is not available on the client. In that case this effect will try to fetch
    // the state as soon as the component is mounted on the client.
    if (typeof asyncStateHolder === 'function') {
      logger?.info('SSR did not serialize any state');
      asyncStateHolder().then((anchorProps) => {
        if (anchorProps) {
          setState({
            fallbackRegistration: true,
            ...anchorProps,
          });
        }
      });
    } else {
      logger?.info('SSR serialized state: ', asyncStateHolder);
    }
  }, []);

  if (!state) {
    return null;
  }

  return <Anchor {...state} />;
};

export default FeatureApp;
