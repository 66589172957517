import React, { useMemo } from 'react';
import { FeatureAppContextProviderProps, FeatureAppContextState } from './FeatureAppTypes';

const FeatureAppContext = React.createContext<FeatureAppContextState | null>(null);

export const FeatureAppContextProvider = ({
  logger,
  inPageNavigationService,
  children,
}: React.PropsWithChildren<FeatureAppContextProviderProps>) => {
  const contextValue: FeatureAppContextProviderProps = useMemo(() => {
    return { logger, inPageNavigationService };
  }, [logger, inPageNavigationService]);

  return <FeatureAppContext.Provider value={contextValue}>{children}</FeatureAppContext.Provider>;
};

function getContext() {
  const context = React.useContext(FeatureAppContext);
  if (context === null) {
    throw Error('Did you forget to wrap your Feature App in <FeatureAppContextProvider>?');
  }
  return context;
}

export function useLogger() {
  return getContext().logger;
}

export function useInPageNavigationService() {
  return getContext().inPageNavigationService;
}
