import { Content, HeadlessContent } from '../FeatureAppTypes';

export const mapHeadlessContent = (content: Content | HeadlessContent): Content => {
  if (content && '__type' in content && content.__type === 'aem-headless') {
    const normalisedContent: Content = {
      anchorName: content.fields.anchorName,
    };
    return normalisedContent;
  }
  return content as Content;
};
