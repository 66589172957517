import { Text, Theme } from '@audi/audi-ui-react';
import React from 'react';
import styled from 'styled-components';
import { useInPageNavigationService, useLogger } from '../FeatureAppContext';
import type { AnchorProps, EditMessageProps } from './AnchorTypes';

const StyledEditMessage = styled.div`
  background-color: ${({ theme }: { theme: Theme }) => theme.colors.ui.success};
  display: block;
`;

const EditMessage: React.FC<EditMessageProps> = ({ title }: EditMessageProps) => (
  <Text as="h1" variant="order3">
    <StyledEditMessage>
      Anchor: [{title}]. This is a placeholder and will not be displayed in the Live Environment.
      Place BELOW the Anchor Navigation Bar FA.
    </StyledEditMessage>
  </Text>
);

const Anchor: React.FC<AnchorProps> = ({
  id,
  title,
  editMode = false,
  fallbackRegistration = false,
}: AnchorProps) => {
  const logger = useLogger();
  const inPageNavigationService = useInPageNavigationService();

  const anchorRef = React.useCallback((node: HTMLDivElement) => {
    if (fallbackRegistration && inPageNavigationService) {
      logger.info('Fallback anchor registration');
      inPageNavigationService.registerAnchor({
        id,
        title,
        getPosition: () => node.offsetTop,
      });
    }
  }, []);

  return (
    <div ref={anchorRef} id={id} data-anchor-name={title}>
      {editMode && <EditMessage title={title} />}
    </div>
  );
};

export default Anchor;
